.center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

.MuiPopper-root .MuiTooltip-tooltip {
  font-size: clamp(0.85rem, 2vw, 1rem) !important;
  background-color: #d32f2f !important;
}

.MuiPopper-root .MuiTooltip-tooltip .MuiTooltip-arrow {
  color: #d32f2f !important;
}

/* Autofill styling to set white text color and remove background color */
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px transparent inset !important; /* Transparent background */
  box-shadow: 0 0 0 1000px transparent inset !important;
  -webkit-text-fill-color: white !important; /* White text color */
  color: white !important; /* Ensure text color is white */
  opacity: 0.85;
  transition: background-color 5000s ease-in-out 0s !important;
}